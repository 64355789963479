import Feature from 'ol/Feature'
import Overlay from 'ol/Overlay'
import Point from 'ol/geom/Point'
import Fill from 'ol/style/Fill'
import Stroke from 'ol/style/Stroke'
import Circle from 'ol/style/Circle'
import Style from 'ol/style/Style'
import Icon from 'ol/style/Icon'
import XYZ from 'ol/source/XYZ'
import BingMaps from 'ol/source/BingMaps'
import { toLonLat } from 'ol/proj'
import { getBottomLeft, getTopRight } from 'ol/extent'
import { getDistance } from 'ol/sphere'
import { DragAndDrop } from 'ol/interaction'
import { GPX, GeoJSON, IGC, KML } from 'ol/format'

import { app, glo, drawNextId } from './globo'
import { dspDiv, inputObject, childCreate, dspLogin, chooseSos, displayWindy, display3Dgeo } from './decor'
import { wrapLon } from './libra'

import { osrm } from './route'
import { chaNew } from './cha'
import { annNew } from './ann'
import { thgNew } from './thg'
import { patNew } from './pat'
import { getTof } from './tof'

let olMenu = null
const mode = 1 // 0 unknown, 1 select, 2 path(linestring), 3 vol(polygon)
const _inters = {}
let mapsrc = []

const fidPointer = 1
const fidOrigin = 2
const geojformat = new GeoJSON()

function viewChanged () { // 'this' is a Map
  const xview = this.getView()
  if (!xview) { console.error('No xview'); return }
  if (!app) { console.error('No App'); return }
  const extent = xview.calculateExtent(this.getSize())
  const bottomLeft = toLonLat(getBottomLeft(extent))
  const topRight = toLonLat(getTopRight(extent))
  const lgn = parseFloat(wrapLon(bottomLeft[0])); app.setv('lgn', lgn)
  const lan = parseFloat(bottomLeft[1]); app.setv('lan', lan)
  const lgx = parseFloat(wrapLon(topRight[0])); app.setv('lgx', lgx)
  const lax = parseFloat(topRight[1]); app.setv('lax', lax)
  const zl = parseFloat(xview.getZoom())
  const center = app.tr2usr(xview.getCenter())
  const _lgc = parseFloat(center[0]); app.setv('lgc', _lgc)
  const _lac = parseFloat(center[1]); app.setv('lac', _lac)
  if (zl) { app.setv('zl', zl) }
  const o = {}
  o.lgc = parseFloat(_lgc.toFixed(6))
  o.lac = parseFloat(_lac.toFixed(6))
  o.lgn = parseFloat(lgn.toFixed(6))
  o.lan = parseFloat(lan.toFixed(6))
  o.lgx = parseFloat(lgx.toFixed(6))
  o.lax = parseFloat(lax.toFixed(6))
  o.zl = parseFloat(zl.toFixed(2))
  try { app.send('sb', o, null) } catch (err) { console.error('VC BUS err: ' + err + '\n' + JSON.stringify(app)) }
  app.updLayers()
}

function posChanged (what) {
  if (!app) { console.error('PosChanged NO Pgc : ' + JSON.stringify(this)); return }
  const _pos = app.getv('_pos')
  if (!_pos) { return }
  const o = {}
  o.w = what
  o.lgt = parseFloat(_pos[0].toFixed(8))
  o.lat = parseFloat(_pos[1].toFixed(8))
  o.alt = app.getv('_alt')
  o.pac = app.getv('_pac')
  o.aac = app.getv('_aac')
  o.spd = app.getv('_spd')
  o.dir = app.getv('_dir')

  // let _trking = app.getTrking();
  //    if( _trking && _trking.X === 'L' ) {
  try {
    app.send('mo', o, function (err, resp) {
      if (err) { console.error('PC resp err:' + JSON.stringify(err)); return }
      app.setProx(resp.body.px)
    })
  } catch (ex) {
    console.error('PC BUS err: ' + ex + '\n' + JSON.stringify(app))
  }
  //    }

  _setPosMarker()
}

function _setPosMarker () {
  const srcPos = app.getSrcs('_p')
  const pos = app.getv('_pos')
  // const pac = app.getv('_pac')
  if (!srcPos || !pos) { return }
  let pFea = srcPos.getFeatureById(0)
  const pnt = new Point(app.tr2map(pos))
  if (!pFea) {
    pFea = new Feature({
      geometry: pnt, ot: 'pos', raw: pos
    })
    pFea.setId(0)
    srcPos.addFeature(pFea)
  } else {
    pFea.setGeometry(pnt)
    pFea.set('raw', pos, false)
  }
  /*
  let rot = 0.0
  const heading = app.getv('_dir')
  if (heading && heading !== 0.0) {
    rot = heading
  }
  */
  pFea.setStyle(new Style({
    image: new Circle({
      radius: 8,
      fill: new Fill({
        color: '#3399CC'
      }),
      stroke: new Stroke({
        color: '#fff',
        width: 2
      })
    })
  }))
  let aFea = srcPos.getFeatureById(1)
  if (!aFea) {
    aFea = new Feature({
      geometry: pnt, ot: 'pac', raw: pos
    })
    aFea.setId(1)
    srcPos.addFeature(aFea)
  } else {
    aFea.setGeometry(pnt)
    aFea.set('raw', pos, false)
  }
  aFea.setGeometry(app.getGeoloc().getAccuracyGeometry())
}

function mapClicked (event) {
  const c = document.getElementById('comment')
  let el = document.getElementById('menu')
  const repere = app.tr2usr(event.coordinate)
  c.innerHTML = 'Rep&egrave;re: ' + repere[1].toFixed(8) + ' ' + repere[0].toFixed(8)
  _setPointer(repere[1], repere[0])
  if (olMenu !== null && typeof olMenu.getPosition() !== 'undefined') {
    dspDiv('menu', false)
    olMenu.setPosition(undefined)
    return
  }
  if (mode !== 1) { return }
  const cent = app.getMap().getView().getCenter()
  const posi = event.coordinate
  let posing = (posi[1] < cent[1]) ? 'bottom-' : 'top-'
  let offX, offY
  if (posi[1] < cent[1]) {
    posing = 'bottom-'
    offY = -10
  } else {
    posing = 'top-'
    offY = 10
  }
  if (posi[0] < cent[0]) {
    posing += 'left'
    offX = 10
  } else {
    posing += 'right'
    offX = -10
  }
  olMenu = new Overlay({
    element: el,
    position: posi,
    positioning: posing,
    stopEvent: true,
    // autoPan: true, autoPanMargin: 10,
    offset: [offX, offY]
  })

  let ih = '<fieldset><legend><strong>Menu</strong> &agrave; ' + repere[1].toFixed(6) + ' ' + repere[0].toFixed(6) +
    ' <div id="menualt" style="display:inline;"></div>' +
    '</legend><table>'
  ih += '<tr><td>dist.air ' + airdistance(repere[1], repere[0]) + '<br/>' +
        '(<div id="osrmdst" style="display:none"></div>)'
  const curpos = app.getv('_pos')
  const origin = app.getv('_ori')
  if (curpos) {
    ih += ' <a target="_blank" href="https://www.google.com/maps/preview/dir/' +
            curpos[1].toFixed(6) + ',' + curpos[0].toFixed(6) + '/' + repere[1].toFixed(6) + ',' + repere[0].toFixed(6) + '/">Navig</a>'
  }
  ih += '</td></tr>'
  ih += '<tr><td><table><tr><td align="center">'
  if (glo.me.id) {
    if (curpos || origin || repere) {
      ih += '<fieldset><span id="cn_h" style="vertical-align:baseline;"><img src="/img/sos_64.png"/></span></fieldset></td><td><table>'
    }
    const closestT = app.getClosest('t')
    if (closestT) {
      const tof = app.getObj('t', closestT.id)
      const km = (closestT.dst / 1000) | 0
      const ti = closestT.id
      let tn
      ih += '<tr><td><input id="cn_tof" type="hidden" value="' + ti + '"/><span style="background:#36e23c" id="cn_i'
      if (tof) {
        tn = tof.n
      } else {
        getTof(closestT.id)
        tn = ti
      }
      ih += '">' + glo.symb.add.c + ' INFO sur ' + tn + '</span><br/><span style="font-size:smaller">d&eacute;co &agrave; ' + km + ' km</span></td><tr>'
    }
    ih += '<tr><td><span id="cn_a">' + glo.symb.add.c + ' Petite annonce</span></td><tr>'
    ih += '<tr><td><span id="cn_k">' + glo.symb.add.c + ' Point d&apos;Int&eacute;r&ecirc;t</span></td><tr>'
    //        ih += '<tr><td><span id="cn_y" style="font-style: italic">'+glo.symb.add.c+' Tchat</span></td><tr>';
    //        ih += '<tr><td><span id="cn_x" style="font-style: italic">'+glo.symb.add.c+' Chemin</span></td><tr>';
    //        ih += '<tr><td><span onclick="creVol();">nouveau Volume</span></td><tr>';
    ih += '<tr><td><span id="windy">' + glo.symb.windy.c + ' &nbsp; Windy</span></td></tr>'
    if (typeof Cesium !== 'undefined') { ih += '<tr><td><span id="dm3d">' + glo.symb.m3d.c + ' &nbsp; 3D</span></td></tr>' }
    ih += '</table>'
  } else {
    ih += '<span id="cn_notid1" style="font-style:italic"><u>Pas identifi&eacute; => Aucune action ... dommage</u></span>'
  }

  ih += '</td></tr></table>'
  ih += '<img src="/img/origine_32.png" width="16" style="vertical-align:baseline;"/> <strong>Origine</strong> : '
  if (origin !== null) {
    ih += '<span id="b_so"><u>D&eacute;placer</u></span> / <span id="b_uso"><u>Supprimer</u></span>'
  } else {
    ih += '<span id="b_so"><u>Marquer au rep&egrave;re</u></span>'
  }
  ih += '<br/>'

  //    ih += '<span id="b_100km">&#x25ef; Cercle 100 km</span><br/>';

  ih += '<tr><td><img src="/img/bugyel.png" width="20" style="vertical-align:baseline;"/> <strong>Position</strong> : '
  if (curpos) {
    ih += curpos[1].toFixed(6) + ' ' + curpos[0].toFixed(6)
  } else {
    ih += '<span id="b_r2p"><u>Marquer au rep&egrave;re</u></span>'
  }
  ih += '</td></tr>'

  /*
  const _trking = app.getTrking()
  if (_trking && _trking.X === 'A') {
    ih += '<tr><td><img src="/img/tracking_32.png" width="20" style="vertical-align:baseline;"/> '
    if (glo.me.id) {
      if (_trking.l > 2) {
        ih += '<span style="color:#F00;font-weight:bold">Tracking en cours ... ' + _trking.t + '</span>&nbsp;<span id="b_trk_sto" style="font-size:150%">' + glo.symb.recoff.c + '</span>'
      } else if (_trking.l >= 0) {
        ih += 'Tracking : d&eacute;marrer&nbsp;<span id="b_trk_sta" style="font-size:140%">' + glo.symb.recon.c + '</span>'
      }
    } else {
      ih += 'Tracking : <span id="cn_notid2" style="font-style:italic"><u>tu dois &ecirc;tre identifi&eacute; ...</u></span>'
    }
    ih += '</td></tr>'
  }
*/

  ih += '<tr><td><div id="menuvol"></div></td></tr>'

  ih += '</table></fieldset>'
  el.innerHTML = ih
  el.style.display = 'block'
  el.style.visibility = 'visible'
  osrm(repere[1].toFixed(6), repere[0].toFixed(6))
  app.getMap().addOverlay(olMenu)
  app.getPointElevation(repere[1].toFixed(6), repere[0].toFixed(6), 'menualt')
  app.getPointVolumes(repere[1].toFixed(6), repere[0].toFixed(6), 'menuvol')

  //    el = document.getElementById('b_100km');
  //    if( el ) el.onclick = function() { _draw100km(repere[1], repere[0]); dspDiv('menu',false); };

  /*
  el = document.getElementById('b_trk_sta')
  if (el) el.onclick = function () { _tracking(3); dspDiv('menu', false) }
  el = document.getElementById('b_trk_sto')
  if (el) el.onclick = function () { _tracking(2); dspDiv('menu', false) }
*/
  el = document.getElementById('b_so')
  if (el) el.onclick = function () { _setOrigin(repere[1], repere[0]); dspDiv('menu', false) }
  el = document.getElementById('b_uso')
  if (el) el.onclick = function () { _setOrigin(null, null); dspDiv('menu', false) }
  el = document.getElementById('b_r2p')
  if (el) el.onclick = function () { _setPosOnRep(repere[1], repere[0]); dspDiv('menu', false) }
  el = document.getElementById('cn_a')
  if (el) el.onclick = function () { objectCreate('a', repere[1], repere[0]); dspDiv('menu', false) }
  el = document.getElementById('cn_k')
  if (el) el.onclick = function () { objectCreate('k', repere[1], repere[0]); dspDiv('menu', false) }
  el = document.getElementById('cn_i')
  if (el) {
    el.onclick = function () {
      const he = document.getElementById('cn_tof')
      if (he) {
        childCreate('i', he.value)
        dspDiv('menu', false)
      }
    }
  }
  el = document.getElementById('cn_notid1')
  if (el) { el.onclick = function () { dspLogin() } }
  el = document.getElementById('cn_notid2')
  if (el) { el.onclick = function () { dspLogin() } }

  /* el = document.getElementById('cn_x');
    if( el ) el.onclick = function() {
//        objectCreate('x', repere[1], repere[0]);
        alert('Chemins: pas encore codé ...');
    };
    el = document.getElementById('cn_y');
    if( el ) el.onclick = function() {
//        objectCreate('y', repere[1], repere[0]);
        alert('Tchats: pas encore codé ...');
    }; */
  el = document.getElementById('cn_h')
  if (el) el.onclick = function () { chooseSos(origin, curpos, repere) }
  el = document.getElementById('windy')
  if (el) el.onclick = function () { displayWindy(repere[1].toFixed(3), repere[0].toFixed(3)) }
  el = document.getElementById('dm3d')
  if (el) el.onclick = function () { display3Dgeo(true, repere[1], repere[0]); dspDiv('menu', false) }
}

const objcreators = {
  a: annNew,
  k: thgNew,
  x: patNew,
  y: chaNew
}
function objectCreate (ot, lat, lgt) {
  if (objcreators[ot]) {
    const e = document.getElementById('popup')
    e.innerHTML = objcreators[ot](lat, lgt)
    dspDiv('popup', true, 120000) // objectCreate
    e.querySelectorAll('span').forEach(function (sp) {
      if (sp.id === 'b_cl') {
        sp.onclick = function () { dspDiv('popup', false) }
      }
      if (sp.id === 'b_ok') {
        sp.onclick = function () { inputObject(ot) }
      }
    })
  }
}

function _setPointer (lat, lgt) {
  let rFea = app.getSrcs('_r').getFeatureById(fidPointer)
  let ptpt
  if (lat !== null && lgt !== null) {
    ptpt = new Point(app.tr2map([lgt, lat]))
    if (!rFea) {
      rFea = new Feature({
        geometry: ptpt, ot: 'ptr', raw: [lgt, lat]
      })
      rFea.setId(fidPointer)
      app.getSrcs('_r').addFeature(rFea)
    } else {
      rFea.setGeometry(ptpt)
      rFea.set('raw', [lgt, lat], false)
    }
    app.setv('_ptr', null)
    const sty = new Style({
      image: new Icon({
        anchor: [0.5, 0.5],
        anchorXUnits: 'fraction',
        anchorYUnits: 'fraction', // 'pixels'
        opacity: 1.0,
        src: '/img/repere_32.png'
      })
    })
    rFea.setStyle(sty)
    app.setv('_ptr', [lat, lgt])
  } else {
    if (rFea) {
      app.getSrcs('_r').removeFeature(fidPointer)
    }
    app.setv('_ptr', null)
  }
}

function _setPosOnRep (lat, lgt) {
  console.info('SetPosOnRep: ' + lat + ' ' + lgt)
  const x = [lgt, lat]
  app.setv('_pos', x)
  //    posChanged('p');
  const o = {}
  o.w = 'p'
  o.lgt = lgt
  o.lat = lat
  o.alt = app.getv('_alt')
  o.pac = app.getv('_pac')
  o.aac = app.getv('_aac')
  o.spd = app.getv('_spd')
  o.dir = app.getv('_dir')

  try {
    app.send('mo', o, function (err, resp) {
      if (err) { console.error('PC resp err:' + JSON.stringify(err)); return }
      app.setProx(resp.body.px)
    })
  } catch (ex) {
    console.error('PC BUS err: ' + ex + '\n' + JSON.stringify(app))
  }

  _setPosMarker()
}

function _setOrigin (lat, lgt) {
  let oFea = app.getSrcs('_r').getFeatureById(fidOrigin)
  let orpt
  if (lat !== null && lgt !== null) {
    orpt = new Point(app.tr2map([lgt, lat]))
    if (!oFea) {
      oFea = new Feature({
        geometry: orpt, ot: 'ori', raw: [lgt, lat]
      })
      oFea.setId(fidOrigin)
      app.getSrcs('_r').addFeature(oFea)
    } else {
      oFea.setGeometry(orpt)
      oFea.set('raw', app.getv('_r'), false)
    }
    const sty = new Style({
      image: new Icon({
        anchor: [0.5, 0.5],
        anchorXUnits: 'fraction',
        anchorYUnits: 'fraction', // 'pixels'
        opacity: 1.0,
        src: '/img/origine_32.png'
      })
    })
    oFea.setStyle(sty)
    app.setv('_ori', [lat, lgt])
  } else {
    if (oFea) {
      app.getSrcs('_r').removeFeature(oFea)
    }
    app.setv('_ori', null)
  }
}

export function currFromPoint () { // p = [ lgt, lat ]
  const origin = app.getv('_ori')
  const curpos = app.getv('_pos')
  const cfp = {}
  if (origin !== null) {
    cfp.p = [origin[1], origin[0]]
    cfp.ll = 'origine'
    cfp.ls = 'orig'
    cfp.co = 'o'
  } else if (curpos) {
    cfp.p = [curpos[0], curpos[1]]
    cfp.ll = 'position'
    cfp.ls = 'posit'
    cfp.co = 'p'
  } else {
    cfp.p = app.tr2usr(app.getMap().getView().getCenter())
    cfp.ll = 'centre'
    cfp.ls = cfp.ll
    cfp.co = 'c'
  }
  return (cfp)
}

export function airdistance (lat, lgt, nomi) {
  let lab = ''
  const cfp = currFromPoint()
  if (!nomi) {
    lab = '<span style="font-weight:bold">'
  }
  const dstm = getDistance(cfp.p, [lgt, lat])
  if (!nomi) {
    lab += cfp.ls + '</span> -> rep&egrave;re '
  }
  let f; let d = 1000; let u = 'km'
  if (dstm < 1000) { d = 1; f = 0; u = 'm' } else if (dstm < 10000) { f = 3 } else if (dstm < 100000) { f = 2 } else { f = 0 }
  return (lab + (dstm / d).toFixed(f) + ' ' + u)
}

// function _tracking (level) { app.setTrack(level) }

function _getGPXint (m) {
  const inter = new DragAndDrop({ formatConstructors: [GPX] })
  inter.on('addfeatures', function (evt) {
    if (!evt.features) { return }
    app.setLayer('_d', true)
    for (const dropfea of evt.features) {
      dropfea.set('ot', '_d')
      dropfea.set('subty', 'gpx')
      dropfea.set('subna', evt.file.name)
      dropfea.setId(drawNextId())
      app.getSrcs('_d').addFeature(dropfea)
      app.getMap().getView().fit(dropfea.getGeometry().getExtent(), { size: m.getSize() })
      console.info('GPX Type:' + dropfea.getGeometry().getType() + ' Layout:' + dropfea.getGeometry().getLayout())
      const usrgeom = dropfea.getGeometry().clone()
      usrgeom.transform(app.getMap().getView().getProjection(), 'EPSG:4326')
      console.info(geojformat.writeGeometry(usrgeom))
    }
  })
  inter.setActive(false)
  m.addInteraction(inter)
  return (inter)
}

// function _draw100km(lat, lgt) {
// let srcPos = app.getSrcs('_p');
// let center = [ lgt, lat ];
// let radius = 100000;
// var circle = new CirCir(center, radius);
// console.log('Circle:'+JSON.stringify(circle));
// var circleFeature = new ol.Feature(circle);
// console.log('Feature:'+JSON.stringify(circleFeature));
// srcPos.addFeature(circleFeature);
// }

function _getKMLint (m) {
  const inter = new DragAndDrop({ formatConstructors: [KML] })
  inter.on('addfeatures', function (evt) {
    if (!evt.features) { return }
    app.setLayer('_d', true)
    for (const dropfea of evt.features) {
      dropfea.set('ot', '_d')
      dropfea.set('subty', 'kml')
      dropfea.set('subna', evt.file.name)
      dropfea.setId(drawNextId())
      app.getSrcs('_d').addFeature(dropfea)
      app.getMap().getView().fit(dropfea.getGeometry().getExtent(), { size: m.getSize() })
      console.info('KML Type:' + dropfea.getGeometry().getType() + ' Layout:' + dropfea.getGeometry().getLayout())
      const usrgeom = dropfea.getGeometry().clone()
      usrgeom.transform(app.getMap().getView().getProjection(), 'EPSG:4326')
      console.info(geojformat.writeGeometry(usrgeom))
    }
  })
  inter.setActive(false)
  m.addInteraction(inter)
  return (inter)
}

function _getIGCint (m) {
  const fc = new IGC({ altitudeMode: 'barometric' })
  const inter = new DragAndDrop({ formatConstructors: [fc] })
  inter.on('addfeatures', function (evt) {
    if (!evt.features) { return }
    app.setLayer('_d', true)
    for (const dropfea of evt.features) {
      dropfea.set('ot', '_d')
      dropfea.set('subty', 'igc')
      dropfea.set('subna', evt.file.name)
      dropfea.setId(drawNextId())
      app.getSrcs('_d').addFeature(dropfea)
      app.getMap().getView().fit(dropfea.getGeometry().getExtent(), { size: m.getSize() })
      console.info('IGC Type:' + dropfea.getGeometry().getType() + ' Layout:' + dropfea.getGeometry().getLayout())
      const usrgeom = dropfea.getGeometry().clone()
      usrgeom.transform(app.getMap().getView().getProjection(), 'EPSG:4326')
      console.info(geojformat.writeGeometry(usrgeom))
    }
  })
  inter.setActive(false)
  m.addInteraction(inter)
  return (inter)
}

function _getGJSint (m) {
  const inter = new DragAndDrop({ formatConstructors: [GeoJSON] })
  inter.on('addfeatures', function (evt) {
    if (!evt.features) { return }
    app.setLayer('_d', true)
    for (const dropfea of evt.features) {
      dropfea.set('ot', '_d')
      dropfea.set('subty', 'gjs')
      dropfea.set('subna', evt.file.name)
      dropfea.setId(drawNextId())
      app.getSrcs('_d').addFeature(dropfea)
      m.getView().fit(dropfea.getGeometry().getExtent(), { size: m.getSize() })
      console.info('GJS Type:' + dropfea.getGeometry().getType() + ' Layout:' + dropfea.getGeometry().getLayout())
      const usrgeom = dropfea.getGeometry().clone()
      usrgeom.transform(m.getView().getProjection(), 'EPSG:4326')
      console.info(geojformat.writeGeometry(usrgeom))
    }
  })
  inter.setActive(false)
  m.addInteraction(inter)
  return (inter)
}

function initDnD () {
  const m = app.getMap()
  if (!m) { return }
  _inters.gpx = _getGPXint(m)
  _inters.kml = _getKMLint(m)
  _inters.igc = _getIGCint(m)
  _inters.gjs = _getGJSint(m)
//    _inters.tjs = _getTJSint(m);
}

function setDnD (yon) { // TODO: vérifier que map existe
  for (const i in _inters) { _inters[i].setActive(yon) }
}

function drawIsolated (data) {
  app.getSrcs('_i').clear()
  for (const iso of data) {
    const fea = new Feature({
      //        'geometry': new LineString([app.tr2map([iso.tg,iso.ta]), app.tr2map([iso.lg,iso.la])]),
      geometry: new Point(app.tr2map([iso.tg, iso.ta])),
      labelPoint: iso.i,
      ot: '_i'
    })

    fea.setId(iso.i)
    fea.set('t', iso.t)
    fea.set('d', iso.d)
    fea.set('gn', iso.gn)
    fea.set('s', iso.s)
    fea.set('ot', '_i')
    app.getSrcs('_i').addFeature(fea)
  }
}

function MapSrc (c, e, n, s) {
  this.code = c
  this.ena = e
  this.name = n
  this.source = s
}
MapSrc.prototype.getSource = function () {
  return (this.source)
}
MapSrc.prototype.setSource = function (src) {
  this.source = src
}
function initMS () {
  mapsrc = []
  mapsrc.push(new MapSrc(0, true, 'ArcGIStopo', new XYZ({
    url: 'https://server.arcgisonline.com/ArcGIS/rest/services/World_Topo_Map/MapServer/tile/{z}/{y}/{x}',
    maxZoom: 19
  })))
  mapsrc.push(new MapSrc(1, true, 'BingHyb', new BingMaps({
    name: 'Bing Hybrid',
    culture: 'fr-fr',
    key: 'AjEkw29FQLHcufsGLAR8QYNTdk2pwQDo-uq_IVC7ucS0S26kbbbUhJzuoQwNk58K',
    imagerySet: 'AerialWithLabels',
    maxZoom: 20
  })))
  mapsrc.push(new MapSrc(2, true, 'OpenTopo', new XYZ({
    url: 'https://{a-c}.tile.opentopomap.org/{z}/{x}/{y}.png',
    transitionEffect: 'resize',
    maxZoom: 15
  })))
  mapsrc.push(new MapSrc(3, true, 'TF Outdoors', new XYZ({
    url: 'https://{a-c}.tile.thunderforest.com/outdoors/{z}/{x}/{y}.png?apikey=ebebecb6897541cc9364178168dd15dd',
    transitionEffect: 'resize',
    maxZoom: 20
  })))
  mapsrc.push(new MapSrc(4, true, 'TF Landscape', new XYZ({
    url: 'https://{a-c}.tile.thunderforest.com/landscape/{z}/{x}/{y}.png?apikey=ebebecb6897541cc9364178168dd15dd',
    transitionEffect: 'resize',
    maxZoom: 20
  })))
  mapsrc.push(new MapSrc(5, true, 'OSM', new XYZ({
    url: 'https://{a-c}.tile.openstreetmap.org/{z}/{x}/{y}.png',
    transitionEffect: 'resize',
    maxZoom: 18
  })))
  /*
  mapsrc.push(new MapSrc(6, true, 'IGN100', new XYZ({
    url: 'https://data.geopf.fr/private/wmts?apikey=ign_scan_ws' +
      '&REQUEST=GetTile&SERVICE=WMTS&VERSION=1.0.0&TILEMATRIXSET=PM' +
      '&LAYER={ignLayer}&STYLE={style}&FORMAT={format}' +
      '&TILECOL={x}&TILEROW={y}&TILEMATRIX={z}',
    ignLayer: 'GEOGRAPHICALGRIDSYSTEMS.MAPS',
    style: 'normal',
    format: 'image/jpeg',
    apikey: 'ign_scan_ws',
    transitionEffect: 'resize',
    maxZoom: 18
  })))
  */

  // mapsrc.push( new MapSrc(3, true, "Stamen(lent)", new XYZ({
  //    url: 'https://stamen-tiles.a.ssl.fastly.net/terrain/{z}/{x}/{y}.jpg',
  //    transitionEffect: 'resize', maxZoom: 20
  //    })));
  // mapsrc.push( new MapSrc(7, true, "Stadia", new StadiaMaps({ layer: 'stamen_watercolor' })));
  // mapsrc.push( new MapSrc(8, true, "StadiaTerrain", new StadiaMaps({ layer: 'stamen_terrain_labels' })));

/* mapsrc.push( new MapSrc(1, true, "ArcGISimag", new XYZ({
    url: 'https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}',
    maxZoom: 19
    })));
mapsrc.push( new MapSrc(3, true, "BingRoad", new BingMaps({
    name: "Bing Road",
    culture: 'fr-fr',
    key : "AjEkw29FQLHcufsGLAR8QYNTdk2pwQDo-uq_IVC7ucS0S26kbbbUhJzuoQwNk58K",
    imagerySet: "Road",
    maxZoom: 19
    })));
mapsrc.push( new MapSrc(4, true, "Cycle", new XYZ({
    url: 'https://{a-c}.tile.thunderforest.com/cycle/{z}/{x}/{y}.png',
    transitionEffect: 'resize', maxZoom: 20
    })));
mapsrc.push( new MapSrc(5, true, "Trans", new XYZ({
    url: 'https://{a-c}.tile.thunderforest.com/transport/{z}/{x}/{y}.png',
    transitionEffect: 'resize', maxZoom: 20
    })));
mapsrc.push( new MapSrc(6, true, "TransDark", new XYZ({
    url: 'https://{a-c}.tile.thunderforest.com/transport-dark/{z}/{x}/{y}.png',
    transitionEffect: 'resize', maxZoom: 20
    })));
mapsrc.push( new MapSrc(10, true, "OSM", new XYZ({
    url: 'https://{a-c}.tile.openstreetmap.org/{z}/{x}/{y}.png',
    transitionEffect: 'resize', maxZoom: 18
    })));
mapsrc.push( new MapSrc(11, false, "OSM", new OSM({ maxZoom: 19})));
*/
}

function getMapSrcByCode (code) {
  let ncode
  if (typeof code === 'string') ncode = parseInt(code)
  else ncode = code
  for (const ms of mapsrc) {
    if (ms.code === ncode) {
      return (ms)
    }
  }
  return (null)
}
function getMapSources () {
  return mapsrc
}
export { geojformat, viewChanged, mapClicked, posChanged, initDnD, initMS, setDnD, getMapSrcByCode, getMapSources, drawIsolated }
