import { Pgc } from './appli'

const glo = {
  me: {},
  m3t: false,
  mob: 'ontouchstart' in window, // running on mobile ?
  d_id: 0, // drawing last feature id
  baseSq: 50.0,
  tomlimit: 15, // à partir de quelle heure default=>demain (pmg,vot,wsh)
  legs: {
    noff: { u: '/img/sit-no.png', l: 'site non officiel' },
    soff: { u: '/img/sit-of.png', l: 'site officiel' },
    detnv: { u: '/img/det-nv.png', l: 'd&eacute;tail non vu' },
    detvu: { u: '/img/det-vu.png', l: 'd&eacute;tails vus' },
    pmgnv: { u: '/img/pmg-nv.png', l: 'post&apos;it non vu' },
    pmgvu: { u: '/img/pmg-vu.png', l: 'post&apos;its vus' },
    evtnv: { u: '/img/evt-nv.png', l: '&eacute;v&egrave;nement non vu' },
    evtvu: { u: '/img/evt-vu.png', l: '&eacute;v&egrave;nements vus' },
    votnv: { u: '/img/vot-nv.png', l: 'vote non vu' },
    votvu: { u: '/img/vot-vu.png', l: 'votes vus' },
    wshnv: { u: '/img/wsh-nv.png', l: 'sortie non vue' },
    wshvu: { u: '/img/wsh-vu.png', l: 'sorties vues' },
    inter: { u: '/img/inter.png', l: 'site sans d&eacute;collage' },
    oris: { u: '/img/oris.png', l: 'orientations sites/d&eacute;cos ok=vert,possible=bleu' }
  },
  legtl: {
    tofm: { u: '/img/tof-m.png', l: 'd&eacute;collage principal' },
    tofmf: { u: '/img/tof-mf.png', l: 'd&eacute;collage principal interdit' },
    tofl: { u: '/img/tof-l.png', l: 'd&eacute;collage repose possible' },
    lan: { u: '/img/lan.png', l: 'atterrissage' },
    lanm: { u: '/img/lan-m.png', l: 'atterrissage principal' },
    lans: { u: '/img/lan-s.png', l: 'atterrissage de secours' }
  },
  legc: {
    rep: { u: '/img/repere.png', l: 'rep&egrave;re' },
    orig: { u: '/img/origin.png', l: 'origine' },
    cpil: { u: '/img/bugwhi.png', l: 'pilote' },
    cano: { u: '/img/bugbla.png', l: 'pilote anonyme' },
    cami: { u: '/img/buggre.png', l: 'pilote ami' },
    cses: { u: '/img/bugyel.png', l: 'cette connexion' },
    coth: { u: '/img/bugblu.png', l: 'autre connexion perso' },
    csos: { u: '/img/bugred.png', l: 'demande d&apos;aide' }
  },
  term: {
    cnx: { t: 'Connexion', p: 'venue d&apos;un pilote sur OPS' },
    rep: { t: 'Rep&egrave;re', p: 'point g&eacute;ographique pour diverses actions' },
    org: { t: 'Origine', p: 'point g&eacute;ographique plus prioritaire que la position' },
    pos: { t: 'Position', p: 'point g&eacute;ographique de la connexion' },
    inf: { t: 'Info', p: 'd&eacute;crit les conditions de vol d&apos;un d&eacute;co', c: true },
    vot: { t: 'Vote', p: 'estimer les chances de voler sur ce déco pour un jour futur', c: true },
    wsh: { t: 'Sortie', p: 'd&eacute;placement pr&eacute;vu sur un d&eacute;co un jour donn&eacute; (+covoiturage)', c: true },
    pmg: { t: 'Post&apos;It', p: 'information &agrave; court terme sur un site', c: true },
    det: { t: 'D&eacute;tail', p: 'infos p&eacute;rennes sur un site (clubs, restrictions ...', c: true },
    evt: { t: '&Eacute;v&egrave;nement', p: 'Comp&eacute;tition, R&eacute;union etc... dans le futur sur un site', c: true },
    sit: { t: 'Site', p: 'Ensemble de d&eacute;cos,atterros et toute info relative (zone g&eacute;o/administrative)' },
    pat: { t: 'Chemin', p: 'trac&eacute; (p&eacute;destre/routier) pour se rendre &agrave; un endroit' },
    trj: { t: 'Trajet', p: 'route (calcul OSRM) depuis l&apos;origine ou la position vers le rep&egrave;re' },
    thg: { t: 'Point d&apos;int&eacute;r&ecirc;t', p: 'info int&eacute;ressante non reli&eacute;e &agrave; un site', c: true },
    ann: { t: 'Annonce', p: 'pour vendre ou acheter du matos', c: true },
    pas: { t: 'Pass&eacute;', p: 'dur&eacute;e en heures int&eacute;ressante (g&egrave;re l&apos;effacement progressif)' },
    fut: { t: 'Futur', p: 'dur&eacute;e en jours int&eacute;ressante (influence l&apos;affichage)' },
    pub: { t: 'Public', p: 'visible pour tous les pilotes connect&eacute;s' },
    prv: { t: 'Priv&eacute;', p: 'visible seulement par le pilote propri&eacute;taire' },
    ami: { t: 'Ami', p: 'permet de diff&eacute;rencier des connexions' },
    ori: { t: 'Orientation', p: 'Angle (rose16) du vent [info,compatible d&eacute;co ...]' },
    dir: { t: 'Direction', p: 'Angle (&deg;) du vent [Balise]' },
    sin: { t: 'Site info', p: 'Site avec quelque chose &agrave; voir (info, post&apos;it, &eacute;v&egrave;nement, vote, sortie)' },
    swi: { t: 'Site <strike>info</strike>', p: 'Site sans rien &agrave; voir (a&eacute;rer l&apos;affichage)' },
    zde: { t: 'Zoom d&eacute;tail', p: 'Niveau de zoom &agrave; partir duquel les d&eacute;cos/atterros sont affich&eacute;s' }
  },
  symb: { // from https://fontawesome.com/
    add: { c: '<i class="fa-solid fa-circle-plus"></i>', l: 'cr&eacute;er un objet' },
    mod: { c: '<i class="fa-solid fa-pen-to-square"></i>', l: 'modifier un objet' },
    valid: { c: '<i class="fa-solid fa-paper-plane"></i>', l: 'valider/envoi' },
    trash: { c: '<i class="fa-solid fa-trash-can"></i>', l: 'supprimer un objet' },
    back: { c: ' &#x25C0; ', l: 'objet pr&eacute;c&eacute;dent' },
    close: { c: '<i class="fa-solid fa-stop"></i>', l: 'fermer la fen&ecirc;tre' },
    idem: { c: '&#x22EF;', l: 'comme au-dessus' },
    plig: { c: '&#x25E4;', l: 'replier la case' },
    deplig: { c: '&#x25E2;', l: 'd&eacute;plier la case' },
    plid: { c: '&#x25E5;', l: 'replier la case' },
    deplid: { c: '&#x25E3;', l: 'd&eacute;plier la case' },
    npage: { c: '<i class="fa-solid fa-circle-arrow-right"></i>', l: 'page suivante' },
    ppage: { c: '<i class="fa-solid fa-circle-arrow-left"></i>', l: 'page pr&eacute;c&eacute;dente' },
    frds: { c: '<i class="fa-solid fa-users"></i>', l: 'amis' },
    frd: { c: '<i class="fa-solid fa-user-plus"></i>', l: '=> ami' },
    unfrd: { c: '<i class="fa-regular fa-user-slash"></i>', l: '=> <strike>ami</strike>' },
    nonvu: { c: '<i class="fa-regular fa-eye-slash"></i>', l: 'garder non-vu' },
    rafa: { c: '<i class="fa-solid fa-caret-up"></i>', l: 'rafale' },
    land: { c: '<i class="fa-solid fa-turn-down"></i>', l: 'repose au d&eacute;co' },
    tel: { c: '<i class="fa-regular fa-phone"></i>', l: 't&eacute;l&eacute;phone' },
    sms: { c: '<i class="fa-regular fa-comment-sms"></i>', l: 'SMS' },
    menu: { c: '&#x2263;', l: 'menu' },
    resync: { c: '<i class="fa-regular fa-arrow-rotate-right"></i>', l: 'resynchroniser' },
    star: { c: '<i class="fa-regular fa-star"></i>', l: '&eacute;toile de vote' },
    navi: { c: '<i class="fa-regular fa-location-crosshairs"></i>', l: 'centrer la carte sur' },
    naviback: { c: '<i class="fa-regular fa-reply"></i>', l: 'naviguer retour' },
    recon: { c: '<i class="fa-regular fa-circle-play"></i>', l: 'enregistrer le parcours' },
    recoff: { c: '<i class="fa-regular fa-circle-stop"></i>', l: 'fin enregistrement' },
    interdit: { c: '&#x26D4;', l: 'interdit' },
    gofind: { c: '<i class="fa-solid fa-magnifying-glass"></i>', l: 'recherche' },
    windy: { c: '<i class="fa-solid fa-wind"></i>', l: 'Windy' },
    m3d: { c: '<i class="fa-solid fa-cubes"></i>', l: 'vue 3D' },
    dump: { c: '&#x21F6;', l: 'dump debug' },
    plainte: { c: '<i class="fa-solid fa-carrot"></i>', l: 'question/plainte' },
    help: { c: '<i class="fa-regular fa-circle-question"></i>', l: 'aide' }
  },
  connecteds: [],
  dispObjStack: [],
  lastVol: -1
}

const app = new Pgc()

export function $ (x) { return document.getElementById(x) }

export function drawNextId () { return glo.d_id++ }

export { glo, app }
