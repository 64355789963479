import BaseObject from 'ol/Object'
import Feature from 'ol/Feature'
import Point from 'ol/geom/Point'
import Style from 'ol/style/Style'
import Icon from 'ol/style/Icon'

import { app, glo } from './globo'
import { timePast, mylink, fdf } from './libra'

const ObjType = 'k'

// {"ot":"k","id":1,"ts":1437379387,"a":43.196226,"g":5.739368,"t":212,"gi":1,"k":4,"c":"potable (ville)"}
// thing.id,
// date_part('epoch'::text, thing.ts)::integer AS ts,
// pgc_lat(thing.poz) AS a,
// pgc_lgt(thing.poz) AS g,
// pgc_alt(thing.poz) AS t,
// thing.guy AS gi,
// thing.kind AS k,
// thing.pub AS p,
// thing.comm AS c,

// local

const thingKinds = {}
thingKinds[0] = { l: 'Personnel', i: '/img/polygon.png' }
thingKinds[1] = { l: 'Club', i: '/img/pgclub_64.png' }
thingKinds[2] = { l: '&Eacute;cole', i: '/img/school_64.png' }
thingKinds[3] = { l: 'Parking', i: '/img/parking_32.png' }
thingKinds[4] = { l: 'Point d&apos;eau', i: '/img/watpnt_64.png' }
thingKinds[5] = { l: 'Camping', i: '/img/camping_64.png' }
thingKinds[6] = { l: 'Point RDV', i: '/img/rdv_32.png' }
thingKinds[7] = { l: 'Auto-stop', i: '/img/autostop_64.png' }
thingKinds[8] = { l: 'Coin sympa', i: '/img/campfire_64.png' }
thingKinds[9] = { l: 'Libell&eacute;', i: '/img/ylw_star.png' }
thingKinds[10] = { l: 'ATTENTION', i: '/img/caution_64.png' }
thingKinds[11] = { l: 'A&eacute;roport', i: '/img/airport_64.png' }
thingKinds[12] = { l: 'H&ocirc;pital', i: '/img/redcross_32.png' }
thingKinds[13] = { l: 'Magasin', i: '/img/store_64.png' }
thingKinds[14] = { l: 'T&eacute;l&eacute;phone', i: '/img/cabinetel_64.png' }
thingKinds[15] = { l: 'Garage', i: '/img/garage_64.png' }
thingKinds[16] = { l: 'Hand&apos;Icare', i: '/img/handicare_64.png' }
thingKinds[17] = { l: 'Radar', i: '/img/radar_32.png' }
thingKinds[18] = { l: 'Arr&ecirc;t de Bus', i: '/img/bus_64.png' }
thingKinds[19] = { l: 'D&eacute;co potentiel', i: '/img/tofpot_64.png' }
thingKinds[20] = { l: 'Vache', i: '/img/vache_64.png' }

function _getKKindLabel (kn) { return thingKinds[kn].l }
function _getKKindImg (kn) { return thingKinds[kn].i }

function thgEdit (thg) {
  let txt = '<form id="frm_' + ObjType + '"><fieldset><legend><span id="b_cl"><span class="pc-cl">' + glo.symb.close.c + '</span>&nbsp;Modifier Point d\'Int&eacute;r&ecirc;t (#' + thg.id + ')</span></legend><table>'
  txt += '<input type="hidden" id="ih_id" value="' + thg.id + '"/>'
  txt += '<tr><td>Public</td><td><input id="ic_p" name="ic_p" type="checkbox" value="pub"'
  if (thg.p === true) { txt += ' checked' }
  txt += '/></td></tr>'
  txt += '<tr><td>Type</td><td><select id="is_k" name="is_k">'
  for (const kn in thingKinds) {
    txt += '<option value="' + kn + '"'
    if (parseInt(kn) === thg.k) { txt += ' selected' }
    txt += '>' + _getKKindLabel(kn) + '</option>'
  }
  txt += '</select></td></tr>'
  txt += '<tr><td colspan="2"><textarea id="it_c" cols="30" rows="2" placeholder="Commentaire">' + thg.c + '</textarea></td></tr>'

  if (app.getv('_ori')) {
    const newPos = app.getv('_ori')
    txt += '<input type="hidden" id="ih_a" value="' + newPos[0] + '"/>'
    txt += '<input type="hidden" id="ih_g" value="' + newPos[1] + '"/>'
    txt += '<tr><td>D&eacute;placer</td><td><input id="ic_o" name="ic_o" type="checkbox" value="ori"/>vers Origine</td></tr>'
  }
  txt += '<tr><td><span id="de_' + ObjType + '_' + thg.id + '">' + glo.symb.trash.c + ' Supprimer</span></td><td align="right"><span id="b_ok" title="OK">Envoi&nbsp;' + glo.symb.valid.c + '</span></td></tr>'
  txt += '</table></fieldset></form>'
  return txt
}

function thgNew (lat, lgt) {
  let txt = '<form id="frm_' + ObjType + '"><fieldset><legend><span id="b_cl"><span class="pc-cl">' + glo.symb.close.c + '</span>&nbsp;Nouveau Point d\'Int&eacute;r&ecirc;t</span></legend><table>'
  txt += '<input type="hidden" id="ih_lat" value="' + lat + '"/>'
  txt += '<input type="hidden" id="ih_lgt" value="' + lgt + '"/>'
  txt += '<tr><td>Public</td><td><input id="ic_p" name="ic_p" type="checkbox" value="pub" checked/></td></tr>'
  txt += '<tr><td>Type</td><td><select id="is_k" name="is_k">'
  for (const kn in thingKinds) {
    txt += '<option value="' + kn + '">' + _getKKindLabel(kn) + '</option>'
  }
  txt += '</select></td></tr>'
  txt += '<tr><td colspan="2"><textarea id="it_c" cols="30" rows="2" placeholder="Commentaire"></textarea></td></tr>'
  txt += '<tr><td></td><td align="right"><span id="b_ok" title="OK">Cr&eacute;er&nbsp;' + glo.symb.valid.c + '</span></td></tr>'
  txt += '</table></fieldset></form>'
  return txt
}

function thgOneLiner (thg) {
  let cnt = 'P.I. '
  const kt = _getKKindLabel(thg.k)

  cnt += kt + ' ' + (thg.p === true ? 'public' : '<i>priv&eacute;</i>') + ' ' + ((thg.c !== null) ? thg.c : '')
  return cnt
}

function thgDisplay (thg) {
  const d = new Date(thg.ts * 1000)
  const od = fdf(d)
  const elapsed = timePast(Math.round(Date.now() - d))
  const kt = _getKKindLabel(thg.k)
  const guy = app.getObj('g', thg.gi)
  let na = '...'
  let titna = na
  app.selectObj(ObjType, thg.id)
  if (!guy) { app.getLater('g', thg.gi) } else {
    na = guy.sn
    titna = guy.fn + ' ' + guy.ln
    if (!na || na === '') { na = titna }
  }
  let txt = '<fieldset><legend><span id="b_cl"><span class="pc-cl">' + glo.symb.close.c + '</span>&nbsp;P.d&apos;I. '
  txt += (thg.p) ? 'public' : '<i>priv&eacute;</i>'
  txt += ': <b>' + kt + '</b> (#' + thg.id + ')</span></legend>'
  txt += '<table><tr><td>Pilote</td><td><span title="' + titna + '">' + na + '</span>'
  if (guy) {
    if (guy.e) {
      txt += ' &nbsp; <a style="font-style:large" href="mailto:' + guy.e + '?subject=From OPS" target="_blank"><img src="/img/sendmail_16.png"/></a>'
    }
    if (guy.p) {
      txt += ' &nbsp; <a style="font-style:large" href="tel://' + guy.p + '" target="_blank">' + glo.symb.tel.c + '</a>'
      txt += ' &nbsp; <a style="font-style:large" href="sms://' + guy.p + '" target="_blank">' + glo.symb.sms.c + '</a>'
    }
  }
  txt += '</td></tr><tr><td><span id="mc_' + ObjType + '_' + thg.id + '" style="font-size:large">' + glo.symb.navi.c + '</span> Position</td><td>' + thg.a.toFixed(5) + ' ' + thg.g.toFixed(5)
  if (thg.t && thg.t !== 0) {
    txt += '&nbsp; Alt:' + thg.t.toFixed(0) + ' m'
  }
  const curpos = app.getv('_pos')
  if (curpos) {
    txt += ' <a target="_blank" href="https://www.google.com/maps/preview/dir/' + curpos[1].toFixed(6) + ',' + curpos[0].toFixed(6) + '/' + thg.a + ',' + thg.g + '/">&lt;=Nav</a>'
  }
  txt += '</td></tr><tr><td>M&agrave;J</td><td>' + od + ' (' + elapsed + ') &nbsp; &nbsp; <img src="' + _getKKindImg(thg.k) + '" width="25" height="25"></td></tr>'
  if (thg.c && thg.c.length > 0) {
    txt += '<tr><td colspan="2"><p>' + mylink(thg.c).replace(/\n|\r/g, '<br/>') + '</p></td></tr>'
  }
  if (glo.me.id) {
    if ((glo.me.id === thg.gi) || (glo.me.id === 1)) {
      //            txt += '<tr><td colspan="2" align="center"><span id="ed_'+ObjType+'_'+thg.id+'">'+glo.symb.mod.c+' Modifier</span></td></tr>';
      txt += '<tr><td><span id="de_' + ObjType + '_' + thg.id + '">' + glo.symb.trash.c + ' Supprimer</span></td>' // &#x2BBF;
      txt += '<td align="right"><span id="ed_' + ObjType + '_' + thg.id + '">Modifier <span style="font-weight:bold;font-size:150%">' + glo.symb.mod.c + '</span></span></td></tr>'
    }
  }
  txt += '</table></fieldset>'
  return (txt)
}

function thgLst (list) {
  if (list) list.forEach((ojs) => thgUpd(ojs))
}

function getThg (oid) {
  const co = app.getObj(ObjType, oid)
  if (co) { thgUpd(co); return }
  try {
    const eb = app.getEB()
    const sid = app.getv('sid')
    eb.send('go', { id: oid, ot: ObjType }, { sid: '' + sid },
      function (err, r) {
        if (err) { return (null) } else { thgIns(r.body[0]) }
      }
    )
  } catch (err) { console.error('GetThg err: ' + err) }
}

function thgIns (ojs) {
  const obj = new Thing(ojs)
  const id = obj.id
  if (app.getObjs(ObjType)[id]) { app.getObjs(ObjType)[id].remove() }
  app.getObjs(ObjType)[id] = obj
  if (obj.gi && !app.getObj('g', obj.gi)) { app.getLater('g', obj.gi) }
  obj.draw()
}

function thgUpd (ojs) {
  let obj = app.getObj(ObjType, ojs.id)
  if (obj) {
    obj.update(ojs)
  } else {
    obj = new Thing(ojs)
    app.getObjs(ObjType)[obj.id] = obj
  }
  if (obj.gi && !app.getObj('g', obj.gi)) { app.getLater('g', obj.gi) }
  obj.draw()
}

function thgDel (obj) {
  const ro = app.getObj(ObjType, obj.id)
  if (ro) ro.remove()
}

function thgStyle (f, reso) {
  if (!f || typeof f === 'undefined') return []
  const thg = app.getObj(ObjType, f.getId())
  if (!thg || typeof thg === 'undefined') return []
  // let kind = f.get('raw').k;
  const kind = thg.k
  const url = _getKKindImg(kind)
  const sty = new Style({
    image: new Icon({
      anchor: [0.5, 0.5],
      anchorXUnits: 'fraction',
      anchorYUnits: 'fraction', // || pixels
      opacity: 1.0,
      rotation: 0.0,
      src: url
    })
  })
  return [sty]
}

function _getThgKey (thg) {
  const mask = thg.k
  return ObjType + mask
}

class Thing extends BaseObject {
  constructor (ojs) {
    super()
    for (const key in ojs) { this[key] = ojs[key] }
    this.ot = ObjType
    this.ik = _getThgKey(ojs)
    this.draw()
  }

  update (ojs) {
    let changed = false
    if (!ojs) {
      const nik = _getThgKey(this)
      if (nik !== this.ik) {
        this.ik = nik
        changed = true
      }
    } else {
      for (const key in ojs) {
        if (typeof this[key] === 'undefined' || this[key] !== ojs[key]) {
          changed = true
          this[key] = ojs[key]
        }
      }
      const nik = _getThgKey(this)
      if (nik !== this.ik) {
        this.ik = nik
        changed = true
      }
    }
    if (changed) this.refresh()
  }

  draw () {
    const fea = new Feature({
      geometry: new Point(app.tr2map([this.g, this.a, this.t]), 'XYZ'),
      ot: ObjType
    })
    fea.setId(this.id)
    app.getSrcs(ObjType).addFeature(fea)
    fea.changed()
  }

  undraw () {
    const fea = app.getSrcs(ObjType).getFeatureById(this.id)
    if (fea) { app.getSrcs(ObjType).removeFeature(fea) }
  }

  refresh () {
    const fea = app.getSrcs(ObjType).getFeatureById(this.id)
    if (fea) fea.changed()
  }

  check () {
    if (!app.getObj('g', this.gi)) app.getLater('g', this.gi)
  }

  remove () {
    this.undraw()
    app.getObjs(ObjType)[this.id] = null
    delete app.getObjs(ObjType)[this.id]
  }

  getEdit () { return (thgEdit(this)) }
  getDisplay () { return (thgDisplay(this)) }
  getOneLiner () { return (thgOneLiner(this)) }
}

export { Thing, thgStyle, getThg, thgLst, thgIns, thgUpd, thgDel, thgNew }
