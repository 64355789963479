import BaseObject from 'ol/Object'
import { Map, View } from 'ol'
import TileLayer from 'ol/layer/Tile'
import { fromLonLat } from 'ol/proj'
import OLCesium from 'olcs'
import { getMapSrcByCode } from './carte'
import { OLCS_ION_TOKEN } from './cesium_token_comm.js'
import { app, glo } from './globo'

const ObjType = 'm3'

class M3d extends BaseObject {
  constructor (ojs) {
    super()
    this._displayed = false
    this.free()
    this.clat = null
    this.clgt = null
    for (const key in ojs) { this[key] = ojs[key] }
    this.ot = ObjType
    this._lays = []
  }

  free () {
    this._camera = null // ol-cesium camera
    this._scene = null // ol-cesium scene
    this._cesium = null // ol-cesium
    this._view = null // the view 3D
    this._baselayer = null // the layer 3D
    this._map = null // the map 3D
    this._inited = false
  }

  display (onoff) {
    if (!this._inited) this.init()
    if (!this._inited) return
    if (onoff === this._displayed) return

    const ze = document.getElementById('Zdiv')
    if (!ze) { glo.m3t = false; return }
    const oe = document.getElementById('m3opt')
    const ce = document.getElementById('m3clo')
    // const ce = document.getElementById('m3com')

    if (onoff === true) {
      oe.style.visibility = ce.style.visibility = ze.style.visibility = 'visible'
      oe.style.display = ce.style.display = ze.style.display = 'block'
      ce.onclick = function () { app.getM3d().display(false) }
    } else {
      if (this._cesium !== null) this._cesium.setEnabled(false)
      oe.style.visibility = ce.style.visibility = ze.style.visibility = 'hidden'
      oe.style.display = ce.style.display = ze.style.display = 'none'
      ce.onclick = null
    }
    if (this._cesium !== null) this._cesium.setEnabled(onoff)
    this._displayed = onoff
    glo.m3t = onoff
  }

  resetLayers () {
    if (!this._inited) this.init()
    for (const layer of this._map.getAllLayers()) {
      this._map.removeLayer(layer)
    }
    this._map.addLayer(this._baselayer)
  }

  addLayer (lay) {
    if (!this._inited) this.init()
    this._map.removeLayer(lay)
    this._map.addLayer(lay)
  }

  setSource (src) {
    if (!this._inited) this.init()
    this._baselayer.setSource(null)
    if (typeof src === 'string') {
      this._baselayer.setSource(getMapSrcByCode(src).getSource())
    } else {
      this._baselayer.setSource(src)
    }
  }

  setCenter (lat, lgt) {
    if (!this._inited) this.init()
    this.clat = lat; this.clgt = lgt
    this._view.setCenter(fromLonLat([this.clgt, this.clat]))
  }

  setZoom (zl) {
    if (!this._inited) this.init()
    this._view.setZoom(zl)
  }

  init () {
    if (this._inited) return
    if (typeof window.Cesium !== 'undefined') { window.Cesium.Ion.defaultAccessToken = OLCS_ION_TOKEN }
    if (this._baselayer === null) {
      this._baselayer = new TileLayer({
        preload: Infinity,
        isBaseLayer: true
      })
    }
    this._baselayer.setSource(null)
    this._baselayer.set('altitudeMode', 'none')

    if (this._map === null) {
      this._map = new Map({
        target: 'm3div',
        // projection: 'EPSG:4326',
        layers: [],
        controls: []
      })
    }

    this._map.setLayers([this._baselayer])

    if (this._view === null) {
      this._view = new View({
        projection: 'EPSG:3857',
        // center: fromLonLat(cent),
        zoom: app.getv('zl'),
        minZoom: 7,
        maxZoom: 20,
        enableRotation: false
      })
    }
    this._map.setView(this._view)
    // this._map.updateSize()
    if (this._cesium === null) {
      this._cesium = new OLCesium({ map: this._map })
      this._cesium.enableAutoRenderLoop()
    }
    if (this._scene === null) {
      this._scene = this._cesium.getCesiumScene()
      this._scene.globe.depthTestAgainstTerrain = true
      this._scene.fog.enabled = true
      this._scene.fog.density = 0.0001
      window.Cesium.createWorldTerrainAsync().then((tp) => { this._scene.terrainProvider = tp })
    }

    // m3cam = this._cesium.getCamera()
    /*
    m3cam.setHeading(0.3);
    m3cam.setTilt(0.8);
    m3cam.setAltitude(5000);
    m3cam.setDistance(100000);
*/
    // this._cesium.setEnabled(true)
    // _startCamera(1000)
    this._inited = true
  }
}

export { M3d }
