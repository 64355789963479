/* global $ */

import { glo, app } from './globo'
import { balSpdColor } from './bal'
import { dspDiv, objectDisplay } from './decor'
import { airdistance, currFromPoint } from './carte'

const PIx2 = Math.PI * 2.0
let waPts = []

/*
export function getCookie(cname) {
let name = cname + "=";
let ca = document.cookie.split(';');
    for( let i=0; i<ca.length; i++) {
    let c = ca[i];
        while (c.charAt(0)==' ') c = c.substring(1);
        if (c.indexOf(name) === 0) return c.substring(name.length, c.length);
    }
    return "";
}
*/

function _getNomiResult (eln, res, s, x) {
  const cfp = currFromPoint()
  const ok = []
  res.forEach((obj) => {
    const cls = obj.class
    const typ = obj.type
    if ((cls === 'place' && (
      typ === 'city' ||
            typ === 'village' ||
            typ === 'suburb' ||
            typ === 'locality' ||
            typ === 'hamlet')) ||
        (cls === 'boundary' && (
          typ === 'administrative' ||
            typ === 'village'))
    ) { obj.dst = airdistance(obj.lat, obj.lon, true); ok.push(obj) }
  })
  ok.sort(function (a, b) { return a.dst - b.dst })
  let ih = '<fieldset><legend><span id="b_cl"><span class="pc-cl">' + glo.symb.close.c + '</span>&nbsp;Nominatim</span> : distances / ' + cfp.ll
  ih += '&nbsp;&nbsp;<span id="rc_0" style="font-size:180%;"> ' + glo.symb.naviback.c + ' </span></legend><table>'
  let idx = 0
  ok.forEach((o) => {
    ih += '<tr><td><span id="sc_' + idx + '" style="font-size:150%;" title="Centrer sur" data-pos="' +
        +parseFloat(o.lat).toFixed(4) + '_' + parseFloat(o.lon).toFixed(4) + '">' + glo.symb.navi.c + '</span> ' + // &#x27B1;
        o.display_name + ' ' +
        parseFloat(o.lat).toFixed(4) + '/' + parseFloat(o.lon).toFixed(4) +
        ' (' + o.dst + ' km)</td></tr>'
    idx++
  })
  const e = document.getElementById(eln)
  if (e) {
    dspDiv(eln, true, 300000)
    e.innerHTML = ih
    e.querySelectorAll('span').forEach(function (sp) {
      if (sp.id.startsWith('sc_')) {
        const chunks = sp.getAttribute('data-pos').split('_')
        sp.onclick = function () { app.setCenterAG(parseFloat(chunks[0]), parseFloat(chunks[1])) }
      }
      if (sp.id.startsWith('rc_')) {
        sp.onclick = function () { app.retCenter() }
      }
      if (sp.id === 'b_cl') {
        sp.onclick = function () { dspDiv('popup', false) }
      }
    })
  }
}

function _errNomiResult (el, x, s, e) { console.error('Nominatim Error:' + e) }

export function nominatim (elem, searchtext) {
  $.ajax({
    type: 'GET',
    url: 'https://nominatim.openstreetmap.org/search',
    data: { q: searchtext, format: 'json', limit: 10 },
    dataType: 'json',
    async: true,
    success: function (r, s, x) { _getNomiResult(elem, r, s, x) },
    error: function (x, s, e) { _errNomiResult(elem, x, s, e) },
    timeout: 15000
  })
}

export function _dispFindResult (elem, types, respbody) {
  const e = document.getElementById(elem)
  e.style.visibility = 'hidden'
  e.style.display = 'none'
  let ih = '<fieldset><legend><span id="b_cl" style="font-weight:bold">' + glo.symb.close.c + '&nbsp;R&eacute;sultat</legend><table>'
  for (const r of respbody) { // id f [ pt pi ] [ d | t ]
    if (r.pt) {
      if (!app.getObj(r.pt, r.pi)) { app.retObj(r.pt, r.pi) }
      ih += '<tr><td><u><span id="go_' + r.pt + '_' + r.pi + '">' + r.f + '</span></u></td></tr>'
    } else {
      if (!app.getObj(types, r.id)) { app.retObj(types, r.id) }
      ih += '<tr><td>'
      if (r.d) { ih += '(' + parseInt(r.d / 1000) + ' km) ' }
      ih += '<u><span id="go_' + types + '_' + r.id + '">' + r.f + '</span></u></td></tr>'
    }
  }
  ih += '</table></fieldset>'
  e.innerHTML = ih
  e.style.display = 'inline'
  e.style.visibility = 'visible'
  e.querySelectorAll('span').forEach(function (sp) {
    if (sp.id.startsWith('go_')) { // go to
      const chunks = sp.id.split('_')
      sp.onclick = function () { objectDisplay(chunks[1], chunks[2]) }
    }
    if (sp.id === 'b_cl') { // close
      sp.onclick = function () { dspDiv(elem, false) }
    }
  })
}

// called from within decor.js
export function findObjectsByText (texte, types, elem) {
  const cfp = currFromPoint()
  const e = document.getElementById(elem)
  e.innerHTML = 'Recherche en cours ...'
  e.style.display = 'block'
  e.style.visibility = 'visible'
  app.send('fo',
    { tx: texte, ty: types, lgt: cfp.p[0].toFixed(6), lat: cfp.p[1].toFixed(6) },
    function (err, resp) {
      if (err) {
        e.innerHTML = 'FindErr:' + JSON.stringify(err)
      } else {
        _dispFindResult('popup', types, resp.body)
        e.style.display = 'none'
        e.style.visibility = 'hidden'
      }
    }
  )
}

// type wihtin 'localStorage', 'sessionStorage'
/* export function storageAvailable(type) {
    try {
    let storage = window[type], x = '__storage_test__';
        storage.setItem(x, x);
        storage.removeItem(x);
        return true;
    }
    catch(e) {
        return e instanceof DOMException && (
            // everything except Firefox
            e.code === 22 ||
            // Firefox
            e.code === 1014 ||
            // test name field too, because code might not be present
            // everything except Firefox
            e.name === 'QuotaExceededError' ||
            // Firefox
            e.name === 'NS_ERROR_DOM_QUOTA_REACHED') &&
            // acknowledge QuotaExceededError only if there's something already stored
            storage.length !== 0;
    }
} */

export function wrapLon (value) {
  const worlds = Math.floor((value + 180) / 360)
  return value - (worlds * 360)
}

export function mousePosFormat (coord) {
  return 'Lat: ' + coord[1].toFixed(6) + ' &nbsp; Lgt: ' + coord[0].toFixed(6)
}

export function zeroPad (num, places) {
  const zero = places - num.toString().length + 1
  return Array(+(zero > 0 && zero)).join('0') + num
}

export function timePast (millis) {
  const chunks = []
  let age = millis / 1000
  if (age > 31557600) { // 365,25 * 86400
    const an = Math.round(age / 31557600)
    chunks.push(an + 'a')
    age -= an * 31557600
  }
  if (age > 2629800) { // 30,4375 * 86400
    const mo = Math.round(age / 2629800)
    chunks.push(mo + 'm')
    age -= mo * 2629800
  }
  if (age > 604800) {
    const se = Math.round(age / 604800)
    chunks.push(se + 's')
    age -= se * 604800
  }
  if (age > 86400) {
    const jo = Math.round(age / 86400)
    chunks.push(jo + 'j')
    age -= jo * 86400
  }
  if (age > 3600) {
    const he = Math.round(age / 3600)
    chunks.push(he + 'h')
    age -= he * 3600
  }
  if (age > 60) {
    const mi = Math.round(age / 60)
    chunks.push(mi + "'")
    age -= mi * 60
  }
  if (age > 0) {
    chunks.push(Math.round(age) + '"')
  }
  if (chunks.length > 0) {
    return chunks.join(' ')
  } else { return '' }
}

export function dir2orin (d) {
  let i = 0
  if (d === 494) { i = 0 } else if (d === 484) { i = 17 } else { i = 1 + parseInt(parseFloat(parseFloat(parseFloat(parseFloat(d) + 11.25) % 360.0) / 22.5)) }
  return (i)
}

export function dir2ori (d) {
  const oris = ['?', 'N', 'NNE', 'NE', 'ENE', 'E', 'ESE', 'SE', 'SSE', 'S', 'SSO', 'SO', 'OSO', 'O', 'ONO', 'NO', 'NNO', 'VAR']
  return (oris[dir2orin(d)])
}

export function dir2oriExtend (d) {
  const orise = ['?', 'Nord', 'NNE', 'NE', 'ENE', 'Est', 'ESE', 'SE', 'SSE', 'Sud', 'SSO', 'SO', 'OSO', 'Ouest', 'ONO', 'NO', 'NNO', 'Variable']
  return (orise[dir2orin(d)])
}

export function dow (d) {
  switch (d) {
    case 0: return ('Dimanche')
    case 1: return ('Lundi')
    case 2: return ('Mardi')
    case 3: return ('Mercredi')
    case 4: return ('Jeudi')
    case 5: return ('Vendredi')
    case 6: return ('Samedi')
  }
  return ('?')
}

export function dow3 (d) {
  return dow(d).substring(0, 3)
}

export function noris2soris (noris) {
  const arr = []
  if (noris === 65535) { return ('Toutes') }
  if (noris === 0) { return ('<i>non renseign&eacute;es</i>') }

  if (noris & Math.pow(2, 0)) { arr.push('N') }
  if (noris & Math.pow(2, 1)) { arr.push('NNE') }
  if (noris & Math.pow(2, 2)) { arr.push('NE') }
  if (noris & Math.pow(2, 3)) { arr.push('ENE') }
  if (noris & Math.pow(2, 4)) { arr.push('E') }
  if (noris & Math.pow(2, 5)) { arr.push('ESE') }
  if (noris & Math.pow(2, 6)) { arr.push('SE') }
  if (noris & Math.pow(2, 7)) { arr.push('SSE') }
  if (noris & Math.pow(2, 8)) { arr.push('S') }
  if (noris & Math.pow(2, 9)) { arr.push('SSO') }
  if (noris & Math.pow(2, 10)) { arr.push('SO') }
  if (noris & Math.pow(2, 11)) { arr.push('OSO') }
  if (noris & Math.pow(2, 12)) { arr.push('O') }
  if (noris & Math.pow(2, 13)) { arr.push('ONO') }
  if (noris & Math.pow(2, 14)) { arr.push('NO') }
  if (noris & Math.pow(2, 15)) { arr.push('NNO') }

  return (arr.join(','))
}

export function orimatch (d, o, a) {
  const mask = Math.pow(2, dir2orin(d) - 1)
  if ((o & mask) !== 0) { return 'o' }
  if ((a & mask) !== 0) { return 'a' }
  return ''
}

export function mylink (t) {
  if (!t) { return t }
  const o = {
    callback: function (text, href) {
      //        return href
      //            ?'<a href="' + href + '" title="' + href + '" target="_blank">' + text + '</a>'
      //            : text;
      if (!href) { return (text) }
      let tag = text
      if (text.match('.facebook.com')) {
        tag = '(Fb)'
      } else if (text.match('.ffvl.fr')) {
        tag = '(FFVL)'
      } else if (text.match('bistrot.rapacesdazur.fr')) {
        tag = '(Bistrot)'
      } else if (text.match('.odsa.')) {
        tag = '(Odsa)'
      } else if (text.match('.doodle.com')) {
        tag = '(Doodle)'
      } else if (text.match('.inscription-facile.com')) {
        tag = '(Inscription sur InscrFacile)'
      } else if (text.match('.list-manage.com')) {
        tag = '(Inscription sur ListManage)'
      } else if (text.match('.mailchi.mp')) {
        tag = '(Msg sur MailChimp)'
      } else if (text.match('teams.microsoft.com')) {
        tag = '(Teams)'
      } else if (text.match('.blogspot.com')) {
        tag = '(BlogSpot)'
      } else if (text.match('chat.whatsapp.com')) {
        tag = '(WhatsApp)'
      } else if (text.match('.google.com')) {
        tag = '(Google)'
      } else if (text.match('.forms.gle')) {
        tag = '(Google)'
      } else if (text.match('.goo.gl/maps')) {
        tag = '(GMap)'
      }

      return ('<a href="' + href + '" title="' + href + '" target="_blank">' + tag + '</a>')
    }
  }
  return window.linkify(t, o)
}

// export var locateds = [ '?', 'Sans', 'Réseau', 'GPS', 'Man' ];
// export function getLocLabel(loc) { return locateds[loc]; }
// export var netconns = [ '?', 'Sans', 'WiFi', 'Mobile', 'SMS' ];
// export function getConLabel(cnx) { return netconns[cnx]; }
// export var modes = [ '?', 'Nuit', 'Jour', 'Exec' ];
// export function getModLabel(mod) { return modes[mod]; }

export function oridecorate (img, cote, okor, avor, noor) {
  if (okor === 0 && avor === 0 && noor === 0) { return img }
  const cnv = document.createElement('canvas')
  const ctx = cnv.getContext('2d', { willReadFrequently: true })
  ctx.canvas.width = cote
  ctx.canvas.height = cote
  ctx.drawImage(img, 0, 0)
  const cen = cote / 2.0

  ctx.beginPath()
  ctx.arc(cen, cen, cote * 0.43 + 1, 0, PIx2, false)
  ctx.arc(cen, cen, cote * 0.30 - 1, 0, PIx2, true)
  ctx.closePath()
  // ctx.globalComposition = 'destination-out';
  ctx.clip()

  const step = Math.PI / 8.0
  let curstate = 0
  let start = (1.5 * Math.PI) - (Math.PI / 16.0)
  let number = 1
  ctx.lineWidth = 1

  for (let exp = 0; exp < 16; exp++) {
    const okma = (okor & Math.pow(2, exp)) !== 0
    const avma = (avor & Math.pow(2, exp)) !== 0
    const noma = (noor & Math.pow(2, exp)) !== 0
    const newstate = (okma ? 1 : 0) + (avma ? 2 : 0) + (noma ? 4 : 0)
    if (newstate !== curstate) {
      if (curstate !== 0) {
        let end = start + (number * step)
        if (end < 0) { end += PIx2 }
        if (end > PIx2) { end -= PIx2 }
        ctx.beginPath()
        ctx.moveTo(cen, cen)
        ctx.arc(cen, cen, cote, start, end)
        ctx.closePath()
        ctx.fillStyle = _arcColor(curstate)
        ctx.fill()
      }
      start = (1.5 * Math.PI) - ((16 - exp) * step) - (step / 2.0)
      if (start > PIx2) { start -= PIx2 }
      if (start < 0) { start += PIx2 }
      number = 1
      curstate = newstate
    } else { number++ }
  }
  if (curstate !== 0) {
    let end = start + (number * step)
    if (end < 0) { end += PIx2 }
    if (end > PIx2) { end -= PIx2 }
    ctx.beginPath()
    ctx.moveTo(cen, cen)
    ctx.arc(cen, cen, cote, start, end)
    ctx.closePath()
    ctx.fillStyle = _arcColor(curstate)
    ctx.fill()
  }

  const newimg = new Image()
  newimg.width = cote
  newimg.height = cote
  newimg.src = cnv.toDataURL()
  return newimg
}

function _arcColor (sta) {
  switch (sta) {
    case 0: return 'rgba(0,0,0,0)'
    case 3:
    case 1: return 'rgba(0,208,0,0.8)'
    case 2: return 'rgba(51,238,238,0.8)'
    case 5:
    case 6:
    case 7:
    case 4: return 'rgba(242,180,180,0.8)'
    // case 4: return 'rgba(238,0,0,0.8)';
  }
}

export function bgInfo (level) {
  switch (level) {
    case 1 : return ('c9e1f2')
    case 2 : return ('c9f2d6')
    case 3 : return ('f2dec9')
    case 4 : return ('f2c9ca')
    default: return ('ffffff')
  }
}

function _dispRaspResult (resp) {
  const sn = resp.sn
  const sid = resp.id
  const site = app.getObj('s', sid)
  const okor = site.oo
  const avor = site.ao
  const run = resp.ru
  const da = resp.da
  const lat = resp.a
  const lon = resp.g
  // let loc = resp['l'];
  const day = new Date(da.substring(0, 4) + '/' + da.substring(4, 6) + '/' + da.substring(6, 8))
  const jour = dow(day.getDay()) + ' ' + day.getDate()
  let ih = '<fieldset><legend><span id="unRasp"><span class="pc-cl">' + glo.symb.close.c + '</span>&nbsp;RASP du site ' + sn + ' <font size="-1">[' + lat.toFixed(4) + ',' + lon.toFixed(4) + ']' + ' (run:' + run + ')</font></span></legend>'
  ih += '<table><tr><th>' + jour + '</th>'
  let imax = 0
  resp.hs.forEach((hour) => {
    const dat = new Date()
    dat.setUTCHours(hour)
    ih += '<th align="center">' + dat.getHours() + 'h</th>'
    imax++
  })
  ih += '</tr>\n'

  ih += '<tr><td colspan="' + (imax + 1) + '"><hr/></td></tr>'

  /*    ih += "<tr><th>Sommet<br/>C.Conv.<br/><font size=\"-1\">(m)</font></th>";
    for( let i=0; i<imax; i++ ) {
    let plaf = resp['cp'][i];
    let di = resp['dt'][i];
    let sp = resp['st'][i];
        // ih += '<th align="center"><canvas id="t' + i + '" style="border:1px solid #ff0000" width="48" height="48"';
        ih += '<th align="center"><canvas id="t' + i + '" width="36" height="36"';
        ih += 'title="' + di.toFixed(0) + '&deg; ' + sp.toFixed(1) + ' km/h"></canvas>';
        ih += '<br/><font size="-1">' + ((plaf>0) ?plaf.toFixed(0) :'-') + '</font></th>';
    }
    ih += "</tr>\n";

    ih += "<tr><th>Moyenne<br/>C.Conv.</th>";
    for( let i=0; i<imax; i++ ) {
    let di = resp['dm'][i];
    let sp = resp['sm'][i];
        // ih += '<th><canvas id="m' + i + '" style="border:1px solid #0000ff" width="48" height="48"';
        ih += '<th><canvas id="m' + i + '" width="36" height="36"';
        ih += 'title="' + di.toFixed(0) + '&deg; ' + sp.toFixed(1) + ' km/h"></canvas></th>';
    }
    ih += "</tr>\n";

    ih += "<tr><th>Thermiques<br/><font size=\"-1\">(m/s)</font></th>";
    for( let i=0; i<imax; i++ ) {
    let spd = resp['ts'][i];
        ih += '<th align="center">' + ((spd>=1.5) ?'<font color="#ff0000">' :'') + ((spd>0.0) ?spd.toFixed(1) :'-') + ((spd>=1.5) ?'</font>' :'') + '</th>';
    }
    ih += "</tr>\n"; */

  ih += '<tr><th>Thermal<br/>Max (m)</th>'
  for (let i = 0; i < imax; i++) {
    const pblh = resp.pb[i]
    ih += '<td align="center">' + pblh + '</td>'
  }
  ih += '</tr>\n'

  ih += '<tr><td colspan="' + (imax + 1) + '"><hr/></td></tr>'

  ih += '<tr><th>Orientation &nbsp;<br/><font size="-1">direction</font></th>'
  for (let i = 0; i < imax; i++) {
    const dir = resp.bd[i]
    const or = orimatch(dir, okor, avor)
    const rain = resp.rt[i]
    const spd = resp.bs[i]
    let col = '#000000'
    const bg = bgColor(or, spd, rain)
    if (or === 'o') { col = '#40a004' } else if (or === 'a') { col = '#0000ff' }
    ih += '<th align="center" bgcolor="' + bg + '"><font color="' + col + '">' + dir2ori(dir) + '<br/><font size="-1">' + dir.toFixed(0) + '&deg;</font></font></th>'
  }
  ih += '</tr>\n'

  ih += '<tr><th>Vitesse<br/><font size="-1">(km/h)</font></th>'
  for (let i = 0; i < imax; i++) {
    const spd = resp.bs[i]
    ih += '<th align="center"><font color="' + spdColor(spd) + '">' + spd.toFixed(0) + '</font></th>'
  }
  ih += '</tr>\n'

  ih += '<tr><th>Terrain<br/><font size="-1">' + resp.te.toFixed(0) + ' m</font></th>'
  for (let i = 0; i < imax; i++) {
    // ih += '<th><canvas id="b' + i + '" style="border:1px solid #00ff00" width="48" height="48"></canvas></th>';
    ih += '<th><canvas id="b' + i + '" width="40" height="40"></canvas></th>'
  }
  ih += '</tr>\n'

  ih += '<tr><td colspan="' + (imax + 1) + '"><hr/></td></tr>'

  ih += '<tr><td style="font-size:smaller">Nuages Haut</td>'
  for (let i = 0; i < imax; i++) {
    const cn = resp.nh[i]
    const bGrey = getGreyBg(cn)
    const fGrey = getGreyFg(cn)
    ih += '<td style="font-size:smaller;text-align:center;background-color:' + bGrey + ';color:' + fGrey + '">' + ((cn > 0) ? cn.toFixed(0) + '%' : '-') + '</td>'
  }
  ih += '</tr>\n'

  ih += '<tr><td style="font-size:smaller">Nuages Moyen</td>'
  for (let i = 0; i < imax; i++) {
    const cn = resp.nm[i]
    const bGrey = getGreyBg(cn)
    const fGrey = getGreyFg(cn)
    ih += '<td style="font-size:smaller;text-align:center;background-color:' + bGrey + ';color:' + fGrey + '">' + ((cn > 0) ? cn.toFixed(0) + '%' : '-') + '</td>'
  }
  ih += '</tr>\n'

  ih += '<tr><td style="font-size:smaller">Nuages Bas</td>'
  for (let i = 0; i < imax; i++) {
    const cn = resp.nl[i]
    const bGrey = getGreyBg(cn)
    const fGrey = getGreyFg(cn)
    ih += '<td style="font-size:smaller;text-align:center;background-color:' + bGrey + ';color:' + fGrey + '">' + ((cn > 0) ? cn.toFixed(0) + '%' : '-') + '</td>'
  }
  ih += '</tr>\n'

  ih += '<tr><th>Pluie <font size="-1">(mm)</font></th>'
  for (let i = 0; i < imax; i++) {
    const rain = resp.rt[i]
    ih += '<th align="center">' + ((rain > 0.05) ? rain.toFixed(1) : '-') + '</th>'
  }

  ih += '</tr></table></fieldset>\n'
  const el = document.getElementById('Rdiv')
  el.style.zIndex = 3333
  // el.style.align='center';
  // el.style.valign='top';
  el.style.position = 'absolute'
  el.style.top = '8%' // 5px
  el.style.left = '3%' // 50px
  el.style.color = '#000'
  el.style.backgroundColor = 'white'
  el.style.maxHeight = '90%'
  el.style.maxWidth = '90%'
  el.innerHTML = ih
  //    el.style.display='block';
  //    el.style.visibility='visible';
  dspDiv('Rdiv', true, 90000)

  const elem = document.getElementById('unRasp')
  elem.onclick = function () { _unraspwind() }

  for (let i = 0; i < imax; i++) { drawArrow('b' + i, resp.bd[i], resp.bs[i]) }
//    for( let i=0; i<imax; i++ ) { drawArrow('m'+i, resp['dm'][i], resp['sm'][i]); }
//    for( let i=0; i<imax; i++ ) { drawArrow('t'+i, resp['dt'][i], resp['st'][i]); }
}

export function allObjectViewed (yon) { // only false
  app.send('aov', { y: false })
}

export function objectViewed (ot, oi, yon) {
  const object = app.getObj(ot, oi)
  if (object) object.see(yon)
  if (!glo.me || !glo.me.id) { return }
  app.send('ov', { ot, oi, y: yon })
  // TODO: faire un popup ok
  const en = 'tr_' + ot + '_' + oi
  const e = document.getElementById(en)
  let bc
  switch (ot) {
    case 'd': bc = '#E6EDE8'; break
    case 'e': bc = '#F7D2EE'; break
    case 'p': bc = '#FFFF7A'; break
    case 'v': bc = '#CDFACA'; break
    case 'w': bc = '#BFF'; break
    default: bc = '#fff'; break
  }
  if (e) {
    e.style.backgroundColor = yon ? '#fff' : bc
  }
}

/* export function tofAvail(oi, yon) {
    app.getObj('t', oi).avail = yon;
    if( ! glo.me || ! glo.me.id ) { return; }
    app.send( 'ta', {'oi':oi, 'y':yon} );
} */

export function callRaspData (snam, sitid, run, day, lat, lgt) {
// console.info("CallRaspData: "+snam+"("+sitid+") run:"+run+" day:"+day);
  app.send('rd',
    { s: snam, i: sitid, r: run, d: day, a: lat, g: lgt },
    function (err, rr) {
      if (err) {
        console.error('RaspErr:' + JSON.stringify(err))
      } else {
        _dispRaspResult(rr.body)
      }
    }
  )
}

function _unraspwind () {
  const el = document.getElementById('Rdiv')
  el.innerHTML = ''
  el.style.display = 'none'
  el.style.visibility = 'hidden'
}

export function bgColor (o, s, r) {
  if (r > 0.05) { return ('#fff') }
  if (o === 'o') {
    if (s > 6 && s < 37) { return ('#c0f79e') }
  } else if (o === 'a') {
    if (s > 6 && s < 37) { return ('#dbecfe') }
  }
  return ('#fff')
}

export function getGreyBg (percent) {
  const x = Math.round((percent * -2.55) + 255)
  return ('rgb(' + x + ',' + x + ',' + x + ')')
}

export function getGreyFg (percent) {
  const x = Math.round((percent * 2.55) + 16)
  return ('rgb(' + x + ',' + x + ',' + x + ')')
}

export function spdColor (s) {
  if (s > 36) { return ('#f00') } else if (s > 26) { return ('#ff9933') } else if (s > 16) { return ('#40a004') } else if (s > 6) { return ('#00f') } else { return ('#000') }
}

export function drawArrow (i, d, s) {
  if (waPts.length < 1) { setWindArrowPts(36) } // 48
  const cnv = document.getElementById(i)
  const ctx = cnv.getContext('2d', { willReadFrequently: true })
  if (!ctx) { return }
  ctx.canvas.width = 36 // 48
  ctx.canvas.height = 36 // 48
  ctx.lineJoin = 'round'
  ctx.clearRect(0, 0, 47, 47)
  ctx.translate(24, 24)
  ctx.rotate(d * 0.017453292519943)
  ctx.translate(-24, -24)
  // draw arrow path
  ctx.beginPath()
  ctx.fillStyle = balSpdColor(s)
  ctx.moveTo(waPts[0][0], waPts[0][1])
  for (let pi = 1, len = waPts.length; pi < len; pi++) {
    ctx.lineTo(waPts[pi][0], waPts[pi][1])
  }
  ctx.closePath()
  ctx.fill()
  // draw speed arrow border
  ctx.lineWidth = 1
  ctx.strokeStyle = '#000'
  ctx.stroke()
}

export function setWindArrowPts (c) {
// var dec = c / 20.0;
  const dec = 0
  waPts = [
    [c / 2.285714 + dec, c / 24.0], // 0

    //              [ c/2.285714 + dec, c/1.297297 ],   // a
    //              [ c/2,796639 + dec, c/1.411765 ],   // b
    //              [ c/2,796639 + dec, c/1.454545 ],   // c

    [c / 2.285714 + dec, c / 1.043478], // 1
    [c / 2.181818 + dec, c / 1.043478], // 2
    //              [ c/2.084033 + dec, c/1.043478 ],   // 1

    [c / 1.371428 + dec, c / 1.411765], // 3
    [c / 1.371428 + dec, c / 1.454545], // 4
    [c / 1.882353 + dec, c / 1.297297], // 5
    [c / 1.882353 + dec, c / 24.0] // 6
  ]
}

export function fd (d) {
  let s = d.getFullYear()
  s += '-' + (d.getMonth() < 9 ? '0' : '') + (d.getMonth() + 1)
  s += '-' + (d.getDate() < 10 ? '0' : '') + d.getDate()
  return (s)
}

export function fdf (d) {
  let s = (d.getDate() < 10 ? '0' : '') + d.getDate()
  s += '/' + (d.getMonth() < 9 ? '0' : '') + (d.getMonth() + 1)
  s += '/' + d.getFullYear()
  return (s)
}

/* export function isMyfriend(gid) {
    if( glo.me && glo.me.frds ) {
        return(glo.me.frds.indexOf(gid) >= 0);
    }
    return false;
} */
export function isMyfriend (gid) {
  const guy = app.getObj('g', gid)
  if (guy) { return guy.friend() }
  return false
}

export function setFriend (act, gid) {
  if (glo.me && glo.me.frds) {
    if (act) { glo.me.frds.push(gid) } else if (glo.me.frds.indexOf(gid) >= 0) { glo.me.frds.splice(glo.me.frds.indexOf(gid), 1) }
  }
}
